import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Flag = ({ imageName }) => {
  const queryData = useStaticQuery(
    graphql`
      query {
        allFile(filter: { absolutePath: { regex: "/flags/" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 70) {
                  originalName
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  );
  const { edges } = queryData.allFile;
  const imageEdge = edges.find(
    edge =>
      (
        ((((edge || {}).node || {}).childImageSharp || {}).fluid || {})
          .originalName || ''
      ).toLowerCase() === (imageName || '').toLowerCase()
  );
  let fluid = imageEdge
    ? (((imageEdge || {}).node || {}).childImageSharp || {}).fluid
    : null;

  return fluid ? <Img className="small-flag" fluid={fluid} /> : '';
};

Flag.propTypes = {
  imageName: PropTypes.string,
};

export default Flag;
