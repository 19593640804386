import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, Marker, TileLayer, Tooltip, Popup } from 'react-leaflet';
import 'react-leaflet-fullscreen/dist/styles.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { Link } from 'gatsby';
import Flag from '../Flag';
import Leaflet from 'leaflet';
import './leaflet.css';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Constants from '../../../static/Constants.jsx';
import styles from './MapView.styles';
import getMapUrl from '../../shared/getMapUrl.js';
import Legend from './Legend.jsx';
import { useTranslation } from 'react-i18next';

const {
  popupContent,
  ProjectSource,
  CountryTitle,
  CountryGrid,
  ProgramNames,
} = styles;

if (Leaflet.Icon) {
  Leaflet.Icon.Default.imagePath = '../node_modules/leaflet';

  delete Leaflet.Icon.Default.prototype._getIconUrl;

  var markerSkyblue = Leaflet.icon({
    // iconUrl: require('../../assets/marker_icons/pulsating-dot.svg'),
    iconUrl: '/marker_icons/pulsating-dot.svg',
    popupAnchor: [0, -2], // same for popup
    iconSize: 20,
  });

  // var markerOrange = Leaflet.icon({
  //   iconUrl: require('../../assets/marker_icons/pulsating-dot-orange.svg'),
  //   popupAnchor: [0, -2], // same for popup
  //   iconSize: 20,
  // });
}

const getMergedPartners = partnerCountries => {
  let output = [];
  if (partnerCountries) {
    output = partnerCountries.reduce((data, currentItem) => {
      // Get the index of the key-value pair.
      const index = data.reduce((n, item, i) => {
        return item.countryName === currentItem.countryName ? i : n;
      }, -1);
      // If the name is found,
      if (index >= 0) {
        // append the current value to its list of values.
        data[index].programName = data[index].programName.concat(
          currentItem.programName
        );
        // Otherwise,
      } else {
        // add the current item to o (but make sure the value is an array).
        const obj = {
          countryName: currentItem.countryName,
          url: currentItem.url,
          coordinates: currentItem.coordinates || null,
          programName: [currentItem.programName],
          countryCode: currentItem.countryCode || '',
          countryID: currentItem.countryID || 0,
          programCode: currentItem.programCode || '',
          projectsCount: currentItem.projectsCount || 0,
          color: currentItem.countryID ? '#2C5F2D' : '#97BC62FF',
          isIntervention: currentItem.isIntervention,
        };
        data = data.concat([obj]);
      }

      return data;
    }, []);
  }
  return output;
};

const CountriesMapParallax = ({ ...props }) => {
  const { isLoading, fallback, programLink } = props;

  let { countries } = props;
  const { t, ready } = useTranslation();
  const legends = [
    // {
    //   icon: require('../../assets/marker_icons/pulsating-dot-orange.svg'),
    //   title: 'Countries With Interventions',
    // },
    {
      // icon: require('../../assets/marker_icons/pulsating-dot.svg'),
      icon: '/marker_icons/pulsating-dot.svg',
      title: t('acp_countries'),
    },
  ];

  countries = getMergedPartners(countries);

  if (isLoading) {
    return <div>{fallback}</div>;
  }

  const view = 'monochrome';
  const url = getMapUrl(Constants[view]);

  const mapRef = useRef(null);

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (Leaflet && Leaflet.latLng && Leaflet.latLngBounds) {
    const corner1 = Leaflet.latLng(-90, -100);
    const corner2 = Leaflet.latLng(90, 100);
    var bounds = Leaflet.latLngBounds(corner1, corner2);
  }

  let countries_data =
    t('countries_data', {
      returnObjects: true,
    }) || [];

  const getCountryData = countryID => {
    let id = countryID;
    if (!ready) {
      return 'Loading...';
    } else {
      let data = countries_data.find(({ countryID }) => countryID == id);
      return data;
    }
  };

  return (
    <div className="projects-map-container">
      {typeof window !== 'undefined' && (
        <>
          <MapContainer
            ref={mapRef}
            style={{ width: '100%', height: '600px' }}
            center={[0, 0]}
            zoom={2.4}
            zoomDelta={1}
            maxZoom={18}
            minZoom={2}
            maxBounds={bounds || []}
            maxBoundsViscosity={1.0}
            // onclick={() => navigate(programLink + '/countries')}
            scrollWheelZoom={false}
            dragging={mobileScreen ? false : true}
          >
            <TileLayer url={url} />

            {countries &&
              (countries || []).map((country, index) => {
                let translatedCountryName = getCountryData(country?.countryID)
                  ?.countryName;
                return country.coordinates != null ? (
                  <Marker
                    key={country.countryName + index}
                    position={[
                      country.coordinates[0] || 0,
                      country.coordinates[1] || 0,
                    ]}
                    // icon={country.isIntervention ? markerOrange : markerSkyblue}
                    icon={markerSkyblue}
                  >
                    <Popup css={popupContent}>
                      <CountryGrid>
                        <Link
                          to={
                            programLink +
                            (country.url ? country.url : '/countries')
                          }
                        >
                          <Flag imageName={country.countryName + '.png'}></Flag>

                          <ProjectSource>
                            <span></span>
                          </ProjectSource>

                          <CountryTitle>
                            <p>{translatedCountryName}</p>
                            {country.projectsCount > 0 ? (
                              <p>{country.projectsCount} Projects</p>
                            ) : (
                              ''
                            )}
                          </CountryTitle>

                          <ProgramNames>
                            {(country.programName || []).map(name => {
                              return <p key={name}>{name}</p>;
                            })}
                          </ProgramNames>
                        </Link>
                      </CountryGrid>
                    </Popup>
                    {!mobileScreen && (
                      <Tooltip>
                        <span>{translatedCountryName}</span>
                      </Tooltip>
                    )}
                  </Marker>
                ) : null;
              })}
          </MapContainer>
          <Legend legends={legends} tiny={false} />
        </>
      )}
    </div>
  );
};

CountriesMapParallax.propTypes = {
  countries: PropTypes.any,
  isLoading: PropTypes.any,
  fallback: PropTypes.any,
  programLink: PropTypes.any,
};

export default CountriesMapParallax;
