import withStyles from '@material-ui/core/styles/withStyles';
import { create } from 'apisauce';
import { Layout } from 'layouts';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Constants from '../../static/Constants';
import dashboardStyle from '../assets/jss/styles/components/dashboardStyle';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import styles from '../styles/home';
//import VerticalFixedNav from '../styles/verticalFixedNav';
import CountryInfo from '../components/CountryInfo';
import useLocalStorage from '../shared/useLocalStorage';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import getHeaderLInks from '../shared/headerLinks';
import QuickLinks from '../components/QuickLinks';
import CircularProgress from '@material-ui/core/CircularProgress';
import CountriesMapParallax from '../components/MapView/CountriesMapParallax';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { navigate } from 'gatsby-link';
import { Slide } from 'react-reveal';
import Twitter from '@material-ui/icons/Twitter';
import NavPills from '../components/NavPills/NavPills';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import getStaticImageURL from '../shared/getStaticImageURL';
import MapWrapper from '../components/GoogleMap/MapWrapper';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });

const { pt_12 } = styles;

const setProgramNames = (programName, countries) => {
  const output = [];
  if ((countries || []).length) {
    countries.map(country => {
      output.push({
        countryName: country.name,
        programName,
        coordinates: country.coordinates,
        url: country.url,
        countryID: country.countryID,
        isIntervention: country.isIntervention,
      });
    });
  }
  return output;
};

const Program = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const { pageHeader, headerLogo, headerLogoScroll, screenName } = data;
  const countries = data.programContent.countries;
  const frontmatter =
    ((data.indexData || {}).childMarkdownRemark || {}).frontmatter || {};
  // const { sliderData, landingData, partnerCountries } = frontmatter;
  const { sliderData, partnerCountries, partners } = frontmatter;
  let programCountries = (partnerCountries || []).length
    ? setProgramNames(data.title, partnerCountries)
    : [];
  const [IPAs, setIPAs] = useState([]);
  const [regionalPartners, setRegionalPartners] = useState([]);
  // const [partnersLoaded, setPartnersLoaded] = useState(false);

  const [projectschartCount, setchartProjectsCount] = useState(0);

  // const [sdgMapData, setSdgMapData] = useLocalStorage(
  //   `${data.programCode ? data.programCode : ''}sdgMapData`,
  //   null,
  //   1
  // );
  // const [projectsData, setProjectsData] = useLocalStorage(
  //   `${data.programCode ? data.programCode : ''}projectsData`,
  //   null,
  //   1
  // );
  // const [countryProjectsData, setCountryProjectsData] = useLocalStorage(
  //   `${data.programCode ? data.programCode : ''}CountryProjectsData`,
  //   null,
  //   1
  // );

  const [sectorsCount] = useLocalStorage(
    `${data.programCode ? data.programCode : ''}sectorsCount`,
    null,
    1
  );

  const [countriesCount, setCountriesCount] = useState(0);

  const [, setLoading] = useState(false);

  const programLink = pageContext.programUrl;

  const headerLinks = getHeaderLInks('Home', programLink, data.noLinks);

  const logos = pageContext.logos || [];

  // useEffect(() => {
  //   setLoading(
  //     !sdgMapData || !projectsData || !countryProjectsData ? true : false
  //   );
  //   const isIE = /*@cc_on!@*/ false || !!(document || {}).documentMode;
  //   if (!isIE) {
  //     smoothScrolling();
  //   }
  //   apiSauceInstance
  //     .get(
  //       Constants.getSdgAndProjectCount + `${programLink ? programLink : ''}`
  //     )
  //     .then(response => {
  //       console.log(response.data)
  //       setSdgMapData(response.data.sdgTile);
  //       setProjectsData(response.data.projectCount);
  //       setCountryProjectsData(response.data.countryProjectsCount);
  //       setSectorsCount(response.data.sectorsCount);
  //       setCountriesCount(response.data.countriesCount);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       if (error) {
  //         setLoading(false);
  //       }
  //     });
  // }, []);

  useEffect(() => {
    getProjectAndCountryCounts();
  }, []);

  function getProjectAndCountryCounts() {
    apiSauceInstance
      .get(Constants.getSdgAndProjectCount + `/${data.programName}`)
      .then(({ data, status }) => {
        // console.log(data)
        if (status !== 400) {
          setchartProjectsCount(data.projectCount);
          setCountriesCount(data.countriesCount);
          //setSectorsCount(data.sectorsCount);
        }
      })
      .catch(error => {
        if (error) {
          setLoading(false);
        }
      });
  }

  const getPublicationLink = pubId => {
    let links = [
      {
        en:
          'https://hub.unido.org/sites/default/files/publications/Flyer_ACP_2021_0.pdf',
        fr:
          'https://hub.unido.org/sites/default/files/publications/Flyer_ACP_2021_0.pdf',
      },
      {
        en:
          'https://businessacp.com/wp-content/uploads/2019/12/Brochure_ACPBusinessFriendly_ppp-1.pdf',
        fr:
          'https://businessacp.com/wp-content/uploads/2019/12/Brochure_ACPBusinessFriendly_ppp-1.pdf',
      },
      {
        en:
          'https://www.unido.org/sites/default/files/unido-publications/2022-12/UNIDO-European-Union-SOTIP-2021-en.pdf',
        fr:
          'https://www.unido.org/sites/default/files/unido-publications/2022-12/UNIDO-European-Union-SOTIP-2021-en.pdf',
      },
    ];
    return links[pubId][i18n.language];
  };

  function getPublicationLinkByName(publication) {
    let link = '';
    // i18n.language
    switch (publication) {
      case 'ACP Business Friendly Meso Level':
        // link =
        //   'https://hub.unido.org/sites/default/files/publications/Flyer_ACP_2021_0.pdf';
        link = getPublicationLink(0);
        break;
      case 'ACP Business-Friendly Programme brochure':
        // link =
        //   'https://businessacp.com/wp-content/uploads/2019/12/Brochure_ACPBusinessFriendly_ppp-1.pdf';
        link = getPublicationLink(1);
        break;
      case 'UNIDO and the European Union':
        // link =
        //   'https://www.unido.org/sites/default/files/unido-publications/2022-12/UNIDO-European-Union-SOTIP-2021-en.pdf';
        link = getPublicationLink(2);
        break;
    }
    if (link) {
      window.open(link, 'blank');
    }
  }

  // useEffect(() => {
  //   getStatisticsDetails();
  // }, []);

  // function getStatisticsDetails() {
  //   apiSauceInstance
  //     .get(Constants.getStatisticsDetails + `/${data.programName}`)
  //     .then(({ data, status }) => {
  //       if (status !== 400) {
  //         setchartProjectsCount(data.projectCount);
  //         if (data.projectsBySDG) {
  //           //  setSDGData(data.projectsBySDG.sdg);
  //           setSdgMapData(data.sdgTile);
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       if (error) {
  //         // do something
  //       }
  //     });
  // }

  useEffect(() => {
    let regional = [],
      nonRegional = [];
    partners.forEach(partner => {
      if (partner.isRegional) regional.push(partner);
      else nonRegional.push(partner);
    });
    setIPAs(
      nonRegional.sort((a, b) => {
        const specialCountries = ['Botswana', 'Mauritius', 'Nigeria'];

        // If both countries are special, maintain original order
        if (
          specialCountries.includes(a.countryName) &&
          specialCountries.includes(b.countryName)
        ) {
          return 0;
        }

        // If only one country is special, put it at the end
        if (specialCountries.includes(a.countryName)) {
          return 1;
        }
        if (specialCountries.includes(b.countryName)) {
          return -1;
        }

        // Otherwise, sort alphabetically
        if (a.countryName < b.countryName) {
          return -1;
        }
        if (a.countryName > b.countryName) {
          return 1;
        }
        return 0;
      })
    );
    setRegionalPartners(
      regional.sort((a, b) => {
        if (a.countryName < b.countryName) {
          return -1;
        }
        if (a.countryName > b.countryName) {
          return 1;
        }
        return 0;
      })
    );
  }, []);

  return (
    <Layout
      programLink={programLink}
      isHome={true}
      headerLinks={headerLinks}
      logos={logos}
      homeSliderData={sliderData}
      counts={{
        projectsCount: projectschartCount,
        sectorsCount: 16,
        countriesCount,
      }}
      projectsCount={projectschartCount}
      sectorsCount={sectorsCount}
      countriesCount={countriesCount}
      programCode={programLink}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Investment Promotion Portal')}</title>
        <link rel="canonical" href={Constants.appUrl} />
      </Helmet>

      <section id="program" className="invest-details">
        <div className="container">
          <GridContainer className="about-grid-cont">
            <GridItem
              md={6}
              sm={12}
              className="left-panel-details"
              style={{ justifyContent: 'unset' }}
            >
              <h3>{t('invest_in_acp')}</h3>

              <div className="iiacp-block" style={{ marginTop: '15px' }}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('acp_home_3'),
                  }}
                ></p>
              </div>

              {/* <hr className="w-100" /> */}
            </GridItem>
            <GridItem md={1} sm={12}>
              {' '}
            </GridItem>
            {/* <GridItem md={4} sm={12} className="right-panel-details">
              <img
                // src={require('../assets/img/invest_desc.png')}
                src={getStaticImageURL('invest_desc.png')}
                alt="Invest in ACP"
              ></img>
            </GridItem> */}
            <GridItem md={5} sm={12}>
              <Slide bottom>
                <NavPills
                  color="customPrimary"
                  alignCenter={true}
                  tabs={[
                    {
                      tabIcon: Twitter,
                      tabContent: (
                        <div className="twitterHeight">
                          <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName={screenName || 'UNIDO'}
                            options={{ height: 560 }}
                          />
                        </div>
                      ),
                    },
                  ]}
                />
              </Slide>
            </GridItem>
          </GridContainer>
        </div>
        <section
          className="region-part"
          style={{
            backgroundImage: `url(${getStaticImageURL('diss.jpg')})`,
          }}
        >
          <p className="home-page-discover">{t('acp_home_4')}</p>

          <Button
            onClick={() => {
              navigate(programLink + '/about');
            }}
            className="btn-secondary"
            variant="contained"
          >
            {t('discover_more')}
          </Button>
        </section>
        <div className="container">
          {/* <GridContainer className="about-grid-cont">
            <GridItem md={6} sm={12} className="left-panel-details">
              <Button
                onClick={() => {
                  navigate(programLink + '/about');
                }}
                className="btn-primary"
                variant="contained"
                style={{ marginTop: '15px' }}
              >
                Discover More
              </Button>
            </GridItem>
            <GridItem md={6} sm={12} className="left-panel-details">
              <p style={{ textAlign: 'justify', marginTop: '10px' }}>
                The Invest in ACP Platform is a user-friendly, free-to-use data
                portal featuring comparative tools, which speed up, simplify and
                expand the process of generating leads and market intelligence
                to enable informed decision-making.
              </p>
            </GridItem>
          </GridContainer> */}
          <GridContainer className="about-grid-cont">
            <GridItem md={6} sm={12} className="left-panel-details">
              <p
                dangerouslySetInnerHTML={{
                  __html: t('acp_home_5'),
                }}
              ></p>
            </GridItem>
            <GridItem md={6} sm={12}>
              <img
                style={{
                  width: '360px',
                  height: '360px',
                  paddingLeft: '50px',
                }}
                // src={require('../assets/img/invest_desc.png')}
                src={getStaticImageURL('invest_desc.png')}
                alt="Invest in ACP"
              ></img>
            </GridItem>
          </GridContainer>
        </div>
      </section>
      <div className="clearfix"></div>
      <section className="countries-intervenstion">
        <img
          className="countries-intervenstion-image"
          src={getStaticImageURL('invention.jpg')}
          alt="countries-intervenstion"
        ></img>
        <div className="country-map">
          <h3>{t('intervention_countries')}</h3>
          {/* leaflet map view */}
          {/* <CountriesMapParallax
            style={{ width: '100%' }}
            countries={programCountries}
            programLink={programLink}
          /> */}
          {/* Google map view */}
          <MapWrapper
            style={{ width: '100%' }}
            countries={programCountries}
            programLink={programLink}
            clusterPage={true}
            pageTypes={{
              industrials: false,
              partners: false,
              projects: false,
              countries: true,
            }}
          />
        </div>
        <div className="container">
          <GridContainer className="countries-contanier">
            <GridItem md={8} sm={12} className="countinterv-details">
              <p>{t('acp_home_6')}</p>
              <Button
                onClick={() => {
                  navigate(programLink + '/countries');
                }}
                className="btn-primary"
                variant="contained"
              >
                {t('read_more')}
              </Button>
            </GridItem>
            <GridItem md={4} sm={12}>
              <div className="contries-counts">
                <div className="progress-bar">
                  <CircularProgress
                    variant="determinate"
                    value={48}
                    className="blue"
                  />
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    className="gray"
                  />
                  <span>48</span>
                </div>
                <div className="progress-details">
                  <p>{t('countries_from')}</p>
                  <span>{t('sub_saharan_africa')}</span>
                </div>
              </div>
              <div className="contries-counts">
                <div className="progress-bar">
                  <CircularProgress
                    variant="determinate"
                    value={16}
                    className="blue"
                  />
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    className="gray"
                  />
                  <span>16</span>
                </div>
                <div className="progress-details">
                  <p>{t('countries_from_v2')}</p>
                  <span>{t('caribbean')}</span>
                </div>
              </div>
              <div className="contries-counts">
                <div className="progress-bar">
                  <CircularProgress
                    variant="determinate"
                    value={15}
                    className="blue"
                  />
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    className="gray"
                  />
                  <span>15</span>
                </div>
                <div className="progress-details">
                  <p>{t('countries_from_v3')}</p>
                  <span>{t('pacific')}</span>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </section>
      <div className="clearfix"></div>
      <section
        className="region-part"
        style={{
          backgroundImage: `url(${getStaticImageURL('diss.jpg')})`,
        }}
      >
        <h3
          style={{ textAlign: 'center' }}
          dangerouslySetInnerHTML={{
            __html: t('acp_home_7'),
          }}
        ></h3>
        <Button
          onClick={() => {
            navigate(programLink + '/projects');
          }}
          className="btn-secondary"
          variant="contained"
        >
          {t('discover_more')}
        </Button>
      </section>
      <section id="partners" className="partner-details">
        <div className="container">
          <h3>{t('our_partners')}</h3>
          <p>{t('ipa')}</p>
          <GridContainer>
            {(IPAs || []).map((partner, index) => {
              return (
                <GridItem
                  xs={6}
                  sm={4}
                  md={2}
                  key={index}
                  className="partner-logo"
                  onClick={() => {
                    partner.id
                      ? navigate(programLink + '/partner/?id=' + partner.id)
                      : '';
                  }}
                >
                  {partner.logo ? (
                    // <Image imageName={partner.logo} />
                    <img
                      alt="default Industrial Park"
                      // src={require(`../assets/img/${partner.logo}`)}
                      src={getStaticImageURL(partner.logo)}
                    />
                  ) : (
                    <img
                      alt="default Industrial Park"
                      // src={require('../assets/img/capeVerde.jpg')}
                      src={getStaticImageURL('capeVerde.jpg')}
                    />
                  )}
                </GridItem>
              );
            })}
          </GridContainer>
          <p>{t('other_partners')}</p>
          <GridContainer>
            {(regionalPartners || []).map((partner, index) => {
              return (
                <GridItem
                  xs={6}
                  sm={4}
                  md={2}
                  key={index}
                  className="partner-logo"
                  onClick={() => {
                    partner.id
                      ? navigate(programLink + '/partner/?id=' + partner.id)
                      : '';
                  }}
                >
                  {partner.logo ? (
                    <img
                      alt="default Industrial Park"
                      // src={require(`../assets/img/${partner.logo}`)}
                      src={getStaticImageURL(partner.logo)}
                    />
                  ) : (
                    <img
                      alt="default Industrial Park"
                      // src={require('../assets/img/capeVerde.jpg')}
                      src={getStaticImageURL('capeVerde.jpg')}
                    />
                  )}
                </GridItem>
              );
            })}
          </GridContainer>
        </div>
        {/* {partnersLoaded ?
            <Container css={ParagraphContent}>
              <span>
                {(partners || []).length ?
                  <>
                    <Container css={ParagraphContent}>
                      <h4>
                        {t('Our Partners - Investment Promotion Agencies (IPAs) ')}
                      </h4>
                    </Container>

                    <GridContainer css={logosGrid}>
                      {(partners || []).map((partner, index) => {
                        return (
                          <GridItem xs={6} sm={4} md={2} key={index}>
                            <Link to={`${programLink}/partner/?id=${partner.institutionID}`}>
                              <PartnerLogo css={ImageStyle}>
                                {partner.institutionLogo &&
                                  partner.institutionLogo.length > 0 ? (
                                  <img
                                    alt="default Industrial Park"
                                    src={partner.institutionLogo}
                                  />
                                ) : (
                                  <Image imageName="capeVerde.jpg" />
                                )}
                              </PartnerLogo>
                            </Link>
                          </GridItem>
                        );
                      })}
                    </GridContainer>
                  </> : ''
                }

                {(regionalPartners || []).length ?
                  <>
                    <Container css={ParagraphContent}>
                      <h4>
                        {t('Our Regional Partners')}
                      </h4>
                    </Container>

                    <GridContainer css={logosGrid}>
                      {(regionalPartners || []).map((partner, index) => {
                        return (
                          <GridItem xs={6} sm={4} md={2} key={index}>
                            <Link to={`${programLink}/partner/?id=${partner.institutionID}`}>
                              <PartnerLogo css={ImageStyle}>
                                {partner.institutionLogo &&
                                  partner.institutionLogo.length > 0 ? (
                                  <img
                                    alt="default Industrial Park"
                                    src={partner.institutionLogo}
                                  />
                                ) : (
                                  <Image imageName="capeVerde.jpg" />
                                )}
                              </PartnerLogo>
                            </Link>
                          </GridItem>
                        );
                      })}
                    </GridContainer>
                  </> : ''
                }
              </span>
            </Container>
            :
            <Container css={loaderContainer} width="100%">
              <Loader
                type="ThreeDots"
                color="#5990ff"
                height={100}
                width={100}
                timeout={10000}
              />
            </Container>
          } */}
      </section>
      <section className="quik-links">
        <img
          className="quik-links-image"
          src={getStaticImageURL('invention.jpg')}
          alt="quik-links"
        ></img>
        <div className="container">
          <QuickLinks programLink={programLink}></QuickLinks>
        </div>
      </section>
      {/* <section className="program-stats">
				<div className="container">
					<h3>
						{t(
							'This website contributes to the achievement of the following sustainable development goals'
						)}
					</h3>
					<GridContainer css={statisticsContainer}>
						<GridItem css={TopGrid}>
							<ImageMain>
								{sdgMapData ? (
									<SDGMapChart
										sdgMapData={sdgMapData}
										programUrl={programLink}
									/>
								) : (
									<Container width="100%">
										<Loader
											type="ThreeDots"
											color="#5990ff"
											height={100}
											width={100}
											timeout={10000}
										/>
									</Container>
								)}
							</ImageMain>
						</GridItem>
					</GridContainer>
				</div>
			</section> */}
      <section className="news-article">
        <div className="container">
          <h3>
            <p> {t('insights_1')} </p>
            <br />
            {t('publications')}
          </h3>
          <GridContainer>
            {/* <GridItem md={2} sm={12} xs={12}>
            </GridItem> */}
            <GridItem md={4} sm={6} xs={12}>
              <Card className="news-card">
                <CardActionArea
                  onClick={() =>
                    getPublicationLinkByName('ACP Business Friendly Meso Level')
                  }
                >
                  <div className="news-media">
                    <img
                      alt="news-media"
                      // src={require('../assets/img/meso-level-brochure.png')}
                      src={getStaticImageURL('meso-level-brochure.png')}
                    />
                  </div>
                  <div className="news-card-details">
                    <CardContent className="card-details">
                      {/* <h6>
                        9 <br /> Nov
                      </h6> */}
                      <h4>{t('publications')}</h4>
                      <p>{t('insights_2')}</p>
                    </CardContent>
                  </div>
                </CardActionArea>
              </Card>
            </GridItem>
            <GridItem md={4} sm={6} xs={12}>
              <Card className="news-card">
                <CardActionArea
                  onClick={() =>
                    getPublicationLinkByName('UNIDO and the European Union')
                  }
                >
                  <div className="news-media">
                    <img
                      alt="news-media"
                      // src={require('../assets/img/article-projects-2.png')}
                      src={getStaticImageURL('article-projects-2.png')}
                    />
                  </div>
                  <div className="news-card-details">
                    <CardContent className="card-details">
                      {/* <h6>
                        5 <br /> May
                      </h6> */}
                      <h4>{t('publications')}</h4>
                      <p>{t('insights_3')}</p>
                    </CardContent>
                  </div>
                </CardActionArea>
              </Card>
            </GridItem>
            <GridItem md={4} sm={6} xs={12}>
              <Card className="news-card">
                <CardActionArea
                  onClick={() =>
                    getPublicationLinkByName(
                      'ACP Business-Friendly Programme brochure'
                    )
                  }
                >
                  <div className="news-media">
                    <img
                      alt="news-media"
                      // src={require('../assets/img/acp-business-frientdly.png')}
                      src={getStaticImageURL('acp-business-frientdly.png')}
                    />
                  </div>
                  <div className="news-card-details">
                    <CardContent className="card-details">
                      {/* <h6>
                        10 <br /> Nov
                      </h6> */}
                      <h4>{t('publications')}</h4>
                      <p>{t('insights_4')}</p>
                    </CardContent>
                  </div>
                </CardActionArea>
              </Card>
            </GridItem>
            {/* <GridItem md={2} sm={12} xs={12}>
            </GridItem> */}
          </GridContainer>
        </div>
      </section>
      <section>
        <div className="container">
          {countries && countries.length === 1 && (
            <section id="countries" className="cd-section" style={pt_12}>
              <div>
                {countries && countries.length > 1 ? (
                  ''
                ) : (
                  <span>
                    {countries && countries[0] && (
                      <div style={{ paddingTop: '150px' }}>
                        <CountryInfo
                          countryData={countries ? countries[0] : null}
                          programUrl={programLink}
                        />
                      </div>
                    )}
                  </span>
                )}
              </div>
            </section>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default withStyles(dashboardStyle)(Program);

Program.propTypes = {
  center: PropTypes.object,
  pageContext: PropTypes.any,
};
