import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const COLORS = ['#3DCC91', '#FEAE65', '#669DB3FF', '#FAA094FF', '#DBB04AFF'];

const SDGBarChart = ({ ...props }) => {
  const { sdgData, countryID, programUrl } = props;

  const prepareSDGData = sdgData => {
    if (sdgData.length > 0) {
      sdgData.forEach(sdg => {
        if (sdg.sdgLabel.toString().indexOf('SDG') < 0) {
          sdg.sdgLabel = 'SDG' + sdg.sdgid;
        }
      });
    }
    return sdgData;
  };

  const data = prepareSDGData(sdgData);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload[0] && payload[0].payload) {
      return (
        <div
          className="custom-tooltip"
          style={{ padding: '0px', textAlign: 'left' }}
        >
          <p
            className="intro"
            style={{
              padding: '0px',
              margin: '0',
              textAlign: 'left',
              fontSize: '12px',
              color: '#3C4858',
            }}
          >{`SDG${payload[0].payload.sdgid}: ${payload[0].payload.sdgTitle}`}</p>
          <p
            className="intro"
            style={{
              padding: '0px',
              margin: '0',
              textAlign: 'left',
              fontWeight: '600',
              color: '#3C4858',
            }}
          >{`Total Projects: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  SDGBarChart.propTypes = {
    sdgData: PropTypes.any,
    payload: PropTypes.any,
    countryID: PropTypes.any,
    programUrl: PropTypes.any,
  };

  CustomTooltip.propTypes = {
    active: PropTypes.any,
    payload: PropTypes.any,
  };

  const handleSDGBarClick = props => {
    if (props.payload && props.payload.sdgid) {
      navigate(
        `${programUrl}/projects?Country=${countryID}&sdg=${props.payload.sdgid}::`
      );
    }
  };

  return (
    <ResponsiveContainer width="100%" aspect={4.0 / 4}>
      {data.length > 0 && (
        <BarChart
          width={360}
          height={200}
          data={sdgData}
          syncId="anyId"
          margin={{
            top: 50,
            right: 10,
            left: -20,
            bottom: 45,
          }}
          fontSize={11}
          style={{ background: '#fff' }}
        >
          <CartesianGrid
            vertical={false}
            strokeWidth={0.1}
            strokeDasharray="1 3"
          />
          <XAxis
            axisLine={true}
            tickLine={false}
            dataKey="sdgLabel"
            fontWeight={600}
            interval={0}
          />

          <YAxis axisLine={true} tickLine={false} />

          <Bar
            barSize={15}
            dataKey="count"
            onClick={handleSDGBarClick}
            label={{
              position: 'top',
              fontSize: 12,
              fill: '#696666',
              fontWeight: '500',
            }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Bar>
          <Legend
            iconType="circle"
            wrapperStyle={{
              lineHeight: '1px',
            }}
            margin={{ top: 50 }}
            fontSize={1}
            align="left"
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={CustomTooltip}
            wrapperStyle={{
              backgroundColor: '#FFF',
              padding: '2px 10px',
              borderRadius: '5px',
              maxWidth: '300px',
              boxShadow: '0 1px 4px 0 rgba(0,0,0,0.14)',
            }}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default SDGBarChart;
