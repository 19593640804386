import { successColor, whiteColor, grayColorMix, hexToRgb } from '../../styles';
import theme from '../../../../../config/theme';

const dashboardStyle = {
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  stats: {
    color: grayColorMix[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  cardCategory: {
    color: grayColorMix[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardCategoryLeft: {
    color: grayColorMix[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
    float: 'left',
  },
  cardCategoryWhite: {
    color: 'rgba(' + hexToRgb(whiteColor) + ',.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColorMix[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: theme.fontWeight.heading,
    fontFamily: theme.fontFamily.heading,
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColorMix[1],
      fontWeight: theme.fontWeight.heading,
      lineHeight: '1',
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: theme.fontWeight.heading,
    fontFamily: theme.fontFamily.heading,
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColorMix[1],
      fontWeight: theme.fontWeight.heading,
      lineHeight: '1',
    },
  },
};

export default dashboardStyle;
