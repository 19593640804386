import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Content } from 'layouts';
import '../styles/prism';
import Fade from 'react-reveal/Fade';
import Done from '@material-ui/icons/Done';
import Dashboard from '@material-ui/icons/Dashboard';
import SDGBarChart from './Charts/SDGBarChart.jsx';
import SectorsPieChartWithPaddingAngle from './Charts/SectorsPieChartWithPaddingAngle.jsx';
import InvestmentBarChartCustomShape from './Charts/InvestmentBarChartCustomShape.jsx';
import GridContainer from './Grid/GridContainer.jsx';
import GridItem from './Grid/GridItem.jsx';
import { create } from 'apisauce';
import Constants from '../../static/Constants.jsx';
import styles from '../templates/country.styles.js';
import { useTranslation } from 'react-i18next';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });

const {
  CountryImage,
  TopSection,
  Title,
  Description,
  TopGrid,
  SectorGridTop,
  SectorImageTop,
  HeadingTop,
  ShortDescriptionTop,
  ImageStyle,
  noContentsStyle,
  fontStyle,
  HeadingSection,
  Listing,
  ProjectsLink,
  ProjectsLinkText,
  Icon,
  IconRed,
  projectsLink,
} = styles;

const CountryInfo = ({ ...props }) => {
  const { countryData, programUrl } = props;

  if (!countryData) {
    return;
  }

  const {
    rawMarkdownBody,
    frontmatter,
  } = countryData.content.childMarkdownRemark;
  const { title, countryID } = frontmatter;
  const fluid = frontmatter.cover.childImageSharp.fluid;

  const [loading, setLoading] = useState(false);
  const [sdgData, setSDGData] = useState();
  const [sectorData, setSectorData] = useState();
  const [investmentTypeData, setInvestmentTypeData] = useState();
  const [projectsCount, setProjectsCount] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    apiSauceInstance
      .get(`${Constants.getChartDetails}/${countryID}`)
      .then(chartData => {
        setProjectsCount(chartData.data.projectCount);

        if (chartData.data.projectsBySDG) {
          setSDGData(chartData.data.projectsBySDG.sdg);
        }
        if (chartData.data.projectsBySector) {
          setSectorData(chartData.data.projectsBySector.sectors);
        }
        if (chartData.data.projectsByInvestmentType) {
          setInvestmentTypeData(
            chartData.data.projectsByInvestmentType.investmentTypes
          );
        }
        setLoading(false);
      })
      .catch(error => {
        if (error) {
          //do something
        }
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <CountryImage>
        <Img style={ImageStyle} fluid={fluid}></Img>
      </CountryImage>

      <Description>
        <Title>{t(title)}</Title>
        <Content input={`<p>${t(rawMarkdownBody)}</p>`}></Content>

        <Link className='hideElement' style={projectsLink} to={`${programUrl}/projects?Country=${countryID}::`}>
          <span css={ProjectsLink}>{projectsCount ? projectsCount : 0}</span>
          <span style={ProjectsLinkText}>
            {projectsCount === 1 ? t('Project') : t('Projects')}
          </span>
        </Link>
      </Description>

      <GridContainer className='hideElement' css={TopSection}>
        {sdgData && sdgData.length ?
          <GridItem xs={12} sm={6} md={4} css={TopGrid}>
            <SectorGridTop>
              <SectorImageTop>
                {sdgData && sdgData.length > 0 ? (
                  <SDGBarChart
                    sdgData={sdgData}
                    countryID={countryID}
                    programUrl={programUrl}
                  />
                ) : (
                    <div style={noContentsStyle}>
                      {loading ? (
                        <p style={fontStyle}>{t('Loading...')}</p>
                      ) : (
                          <p style={fontStyle}>{t('No Projects to display')}</p>
                        )}
                    </div>
                  )}
              </SectorImageTop>
              <HeadingTop>{t('Projects By Top 5 SDG')}</HeadingTop>
              <ShortDescriptionTop>
                {projectsCount} {t('Projects')}
              </ShortDescriptionTop>
            </SectorGridTop>
          </GridItem>
          : ''
        }
        {sectorData && sectorData.length ?
          <GridItem xs={12} sm={6} md={4} css={TopGrid}>
            <SectorGridTop>
              <SectorImageTop>
                {sectorData && sectorData.length > 0 ? (
                  <SectorsPieChartWithPaddingAngle
                    sectorData={sectorData}
                    countryID={countryID}
                    programUrl={programUrl}
                  />
                ) : (
                    <div style={noContentsStyle}>
                      {loading ? (
                        <p style={fontStyle}>{t('Loading...')}</p>
                      ) : (
                          <p style={fontStyle}>{t('No Projects to display')}</p>
                        )}
                    </div>
                  )}
              </SectorImageTop>
              <HeadingTop>{t('Projects By Top 5 Sectors')}</HeadingTop>
              <ShortDescriptionTop>
                {projectsCount} {t('Projects')}{' '}
              </ShortDescriptionTop>
            </SectorGridTop>
          </GridItem>
          : ''
        }
        {investmentTypeData &&
          investmentTypeData.length ?
          <GridItem xs={12} sm={6} md={4} css={TopGrid}>
            <SectorGridTop>
              <SectorImageTop>
                {investmentTypeData &&
                  investmentTypeData.length > 0 &&
                  projectsCount > 0 ? (
                    <InvestmentBarChartCustomShape
                      investmentTypeData={investmentTypeData}
                      countryID={countryID}
                      programUrl={programUrl}
                    />
                  ) : (
                    <div style={noContentsStyle}>
                      {loading ? (
                        <p style={fontStyle}>{t('Loading...')}</p>
                      ) : (
                          <p style={fontStyle}>{t('No Projects to display')}</p>
                        )}
                    </div>
                  )}
              </SectorImageTop>
              <HeadingTop>{t('Projects By Investment Type')}</HeadingTop>
              <ShortDescriptionTop>
                {projectsCount} {t('Projects')}{' '}
              </ShortDescriptionTop>
            </SectorGridTop>
          </GridItem>
          : ''
        }
      </GridContainer>

      <Fade right>
        <GridContainer>
          <GridItem xs={12}>
            <HeadingSection>
              <h4>{t('UNIDO Programme Interventions')}</h4>
            </HeadingSection>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Listing>
              <li>
                <Dashboard style={IconRed} />
                <span>
                  <strong>{t('Improved investment')}</strong>
                </span>
                <p>
                  {t(
                    'Collection and analysis of firm-level data on FDI structure,performance, impact and investor perceptions'
                  )}
                </p>
              </li>
              <li>
                {t('Investment facilitation, after-care and FDI-SME linkages')}
              </li>
              <li>
                {t('checkInvestment opportunity development and targeting')}{' '}
              </li>
            </Listing>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Listing>
              <li>
                <Dashboard style={IconRed} />
                <span>
                  <strong>{t('Collection and analysis')}</strong>
                </span>
                <p>
                  {t(
                    'Collection and analysis of firm-level data on FDI structure,performance, impact and investor perceptions'
                  )}
                </p>
              </li>

              <li>
                {t(
                  'Collection and analysis of firm-level data on FDI structure,performance, impact and investor perceptions'
                )}
              </li>
              <li>
                <Done style={Icon} />
                {t('Investment promotion strategy development')}
              </li>
              <li>
                <Done style={Icon} />
                {t('Evidence-based policy advocacy')}
              </li>
            </Listing>
          </GridItem>
        </GridContainer>
      </Fade>

    </div>
  );
};

export default CountryInfo;

CountryInfo.propTypes = {
  countryData: PropTypes.object,
  programUrl: PropTypes.any,
};
